import React from 'react';
import PropTypes from 'prop-types';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

const ExtLink = ({children, to, ...rest}) => (
  <OutboundLink href={to} rel="noopener noreferrer" target="_blank" {...rest}>
    {children}
  </OutboundLink>
);

ExtLink.defaultProps = {
  children: null,
  to: '#',
};

ExtLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default ExtLink;
