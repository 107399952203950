import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';

import {Form} from '@evag/ec-react';

import '@evag/ec-react/dist/main.css';

import CampaignFeedback from '../components/CampaignFeedback';
import CampaignForm from '../components/CampaignForm';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function render({data}) {
  CampaignForm.channelId = data.site.meta.channel;

  return (
    <Layout>
      <SEO title={data.site.meta.title} />

      <Hero
        logo={data.logo.childImageSharp.fluid}
        channel={data.site.meta.channel}
      />

      <main className="main">
        <div className="wrap">
          <p>Durante a crise do coronavírus, a violência contra mulher tem crescido de maneira acentuada. Só em São Paulo o aumento foi de 44,9% e, no Rio de Janeiro, cerca de 50%. O recrudescimento da violência de gênero durante a pandemia pode se explicar por dois principais fatores: por um lado, o agravamento das desigualdades econômicas, como o que estamos vivendo, via de regra tem como consequência a elevação dos índices de violência contra a mulher; por outro lado, para aquelas mulheres que têm condições de praticar o isolamento social - necessário para que os sistemas de saúde não colapsem -, a própria casa nem sempre é o lugar mais seguro para se estar.</p>

          <p>Por isso, a campanha #NósEstamosJuntas se propõe a fortalecer mulheres, criando redes de solidariedade, apoio e amparo para romper com os ciclos da violência que muitas de nós nos encontramos. A luta feminista sempre se apoiou em laços de solidariedade essenciais para que possamos nos fortalecer. No período que estamos atravessando, o isolamento pode reforçar a sensação de solidão e de falta de alternativa para a situação de violência em que muitas mulheres se encontram. Neste sentido, nossa campanha procura fortalecer a solidariedade feminista conectando mulheres nos territórios, referenciando coletivos, organizações e serviços aos quais podemos recorrer, orientando sobre a identificação da violência e mobilizando para cobrar das instituições e governos mecanismos efetivos de combate à violência contra a mulher.</p>

          <p>Nós somos diversas. Somos professoras, trabalhadoras da saúde na linha de frente da luta contra o covid-19, desempregadas, filhas de empregadas domésticas, trabalhadoras informais, estudantes, mulheres negras, indígenas, quilombolas, LBTs, mulheres com deficiência e mulheres surdas, de interiores e cidades grandes. A violência afeta a todas nós, mesmo que de maneiras distintas. No entanto, o acesso à proteção e à garantia dos nossos direitos nem sempre é o mesmo. Muitas mulheres em áreas dominadas por milícias ou pelo tráfico de drogas não têm como recorrer ao Estado para se proteger. Os mecanismos de proteção da Lei Maria da Penha muitas vezes não funcionam para mulheres que vivem em aldeias e comunidades isoladas, por exemplo, já que as medidas protetivas que deveriam garantir o distanciamento do agressor tem pouca efetividade nesses espaços. No caso das mulheres surdas, essas costumam ter maior dificuldade para realizar denúncias já que a língua utilizada por elas é a Libras, e não há profissionais tradutores e intérpretes disponíveis nas delegacias de atendimento à mulher. Ou ainda, também por conta da barreira linguística, fica impossível realizar uma denúncia pelo 180.</p>

          <p>Há muito tempo o Brasil não prioriza a garantia dos direitos das mulheres, mas nos últimos anos este cenário vem se agravando diante da precarização dos serviços públicos, como o SUS (Sistema Único de Saúde) e o SUAS (Sistema Único de Assistência Social), do corte brutal de investimentos em políticas sociais implementado pela emenda 95 do teto de gastos, do avanço da grilagem e desmatamento em terras indígenas e do aumento da violência de Estado nas favelas e periferias.</p>

          <p>Na presidência do país, temos um misógino, adorador da tortura e da ditadura, condenado por apologia ao estupro e que colocou à frente do Ministério da Mulher uma fundamentalista anti-feminista que despreza os direitos conquistados pelas mulheres historicamente. A saída de Bolsonaro da presidência do país é uma urgência incontornável da luta pela vida das mulheres.</p>

          <p>Defender a nós mesmas da violência de gênero significa lutar por investimento do estado em políticas de prevenção e acolhimento às mulheres em situação de violência; significa garantir o atendimento à população de rua e respeitar os direitos humanos das mulheres em privação de liberdade; significa fortalecer as equipes dos serviços de acolhimento e assistência às mulheres em situação de violência, garantindo abrigos para que as mulheres possam sair de seus lares violentos; significa a ampla divulgação ampla de nossos direitos.</p>

          <p>Dessa forma, exigimos:</p>

          <ul>
            <li>Fora Bolsonaro!</li>
            <li>Aprovação dos Projetos de Lei de proteção às mulheres durante a pandemia no Congresso Nacional (PL 1552/20, PL 1267/20, PL 1291/20, PL 2765/20, PL 2477/20, PL 2508/20, entre outros)</li>
            <li>Fim da Emenda Constitucional 95 (Teto de Gastos) e ampliação do investimento em prevenção à violência de gênero e proteção à mulheres em situação de violência;</li>
            <li>Defesa da Lei Maria da Penha e de sua aplicação integral em todas as regiões do país;</li>
            <li>Funcionamento 24h das delegacias da mulher, incluindo finais de semana;</li>
            <li>Marco temporal não! Contra o PL da grilagem e pelo respeito à demarcação das terras indígenas;</li>
            <li>Suspensão da operações policiais em favelas e periferias durante a pandemia;</li>
            <li>Garantia da acessibilidade para mulheres com deficiência e mulheres surdas aos mecanismos de denúncia de violência contra as mulheres, bem como nos serviços de acolhimento e atendimento a mulheres em situação de violência.</li>
          </ul>

          <p>Para defender a vida das mulheres, precisamos fortalecer a nossa auto-organização. Devemos construir redes de solidariedade ativa, apoio e amparo às mulheres em situação de violência. Neste sentido, nossa campanha propõe algumas ações:</p>

          <ul>
            <li>Disseminação do manual de combate à violência contra mulher;</li>
            <li>Ampliação do mapeamento de solidariedade com serviços públicos e voluntárias;</li>
            <li>Divulgação em mercados, condomínios, muros e vizinhanças de cartazes informativos sobre como proceder em caso de violência contra mulher, bem como divulgar essas mensagens com carros de som nas comunidades;</li>
            <li>Multiplicar iniciativas de debates online e em rádios comunitárias sobre a violência contra mulher;</li>
            <li>Construir ações em conjunto com sindicatos, entidades estudantis e nas comunidades.</li>
          </ul>

          <p>Não vamos nos calar diante da violência. Mesmo afastadas, nós estamos juntas!</p>

          <p><strong>Assinam esse manifesto:</strong></p>

          <p style={{fontSize: '0.85em', lineHeight: '1.2em'}}>
            Sâmia Bomfim - Deputada Federal pelo PSOL/SP<br />
            Fernanda Melchionna - Deputada Federal pelo PSOL/RS<br />
            Luciana Genro - Candidata a Presidente em 2014 e Deputada Estadual pelo PSOL/RS<br />
            Monica Seixas - Deputada Estadual pelo PSOL/SP<br />
            Rosana Pinheiro Machado - Antropóloga e professora da UFSM/RS<br />
            Erika Hilton - Codeputada estadual de São Paulo pela Bancada Ativista<br />
            Chirley Pankará - Codeputada estadual de São Paulo pela Bancada Ativista<br />
            Daniela Mussi - Editora da Revista Outubro e coordenadora da Universidade Emancipa / SP<br />
            Joanna Burigo - Coordenadora da Emancipa Mulher, fundadora da Casa da Mãe Joanna e mestre em Gênero, Mídia e Cultura<br />
            Diolinda Souza - Frente Nacional de Luta<br />
            Fernanda Miranda - Vereadora de Pelotas/RS pelo PSOL<br />
            Mariana Conti - Vereadora de Campinas/SP pelo PSOL<br />
            Fernanda Garcia - Vereadora de Sorocaba/SP pelo PSOL<br />
            Eliana Bogéa - Ouvidora Geral Externa da Defensoria Pública do Estado do Pará<br />
            Vivi Reis - Executiva estadual do PSOL PA, fisioterapeuta e suplente deputada federal<br />
            Andrea Werner - Jornalista, escritora e ativista<br />
            Kezia Wandressa da Costa Lima - Presidenta do PSOL-Boa Vista (RR)<br />
            Annie Schmaltz Hsiou - Professora da Faculdade de Filosofia, Ciências e Letras de Ribeirão Preto - USP<br />
            Fabiana Severi - Professora da Faculdade de Direito de Ribeirão Preto da Universidade de São Paulo<br />
            Maíra Martins da Silva - Docente e vice-coordenadora do curso de Engenharia Mecatrônica da EESC/USP, e membro do Núcleo de Direitos Humanos do Campus da USP São Carlos.<br />
            Angélica Martins - Docente em Saúde Mental do DEnf - UFSCar.<br />
            Diene Monique Carlos - Docente em Saúde da Criança e Adolescente do DEnf - UFSCar<br />
            Márcia Regina Cangiani Fabbro - Docente em Saúde da Mulher do DEnf - UFSCar<br />
            Natália R. Salin - Obstetriz e docente em Saúde da Mulher do DEnf - UFSCar<br />
            Fernanda Castelano Rodrigues - Docente do DL - UFSCar<br />
            Angela Alves Machado - Professora de História da rede estadual no Paraná, mãe e militante da educação<br />
            Sara Azevedo - Coordenadora do Emancipa MG<br />
            Luana Alves - Coordenadora do Emancipa SP<br />
            Fabiana Amorim - Diretora de cultura da UNE<br />
            Mayra Ribeiro - Canal “Quem não viu a Mayra vê”<br />
            Ana Luisa Medeiros - PSOL Uberlândia (MG)<br />
            Patrícia Felix - Conselheira Tutelar (RJ)<br />
            Ana Caterine - Diretora SindEnfermeiros (DF)<br />
            Ariadne Natal - Socióloga e pesquisadora do Núcleo de Estudos da Violência da USP (SP)<br />
            Natália Cristina Antunes Gabriel - Vice Diretora de Base Sintunesp - Câmpus São José do Rio Preto (SP)<br />
            Jéssica Daiana de Oliveira - Diretora do Sinpro - Sindicato dos professores de São José do Rio Preto (SP)<br />
            Juliana dos Santos Costa - Movimento Negro em Rio Preto (SP)
          </p>

          <p><strong>Também assinam esse manifesto os seguintes coletivos, entidades e organizações:</strong></p>

          <p style={{fontSize: '0.85em', lineHeight: '1.2em'}}>
            Coletivo Juntas!<br />
            Coletivo Juntos<br />
            Rede Emancipa<br />
            MOVER- Plataforma sindical anticapitalista<br />
            Frente Nacional de Luta<br />
            Emancipa Mulher<br />
            Fórum Estadual de Mulheres Negras do Rio de Janeiro<br />
            Ouvidoria Geral Externa da Defensoria Pública do Estado do Pará<br />
            Conselho Regional de Psicologia do Pará<br />
            União dos Estudantes de Ensino Superior de Santarém<br />
            União Estadual dos Estudantes de Minas Gerais<br />
            União Estadual dos Estudantes do Ceará<br />
            União dos Estudantes de Saquarema (RJ)<br />
            ASERGHC (Associação dos Servidores do Grupo Hospitalar Conceição - RS)<br />
            Associação Docente da UERJ (RJ)<br />
            SIMPA - Sindicato dos Municipários de Porto Alegre (RS)<br />
            SINASEFE - Acres<br />
            Sindicato de Trabalhadores e Trabalhadoras do Instituto Federal do Rio de Janeiro<br />
            Sindicato dos docentes da Ufopa - SINDUFOPA (PA)<br />
            Sindicato dos Professores de São José do Rio Preto (SP)<br />
            Sindicato dos Professores e Professoras de Guarulhos (SP)<br />
            Sindicato dos técnicos da UFAM e IFAM - SINTESAM (AM)<br />
            SINTEST/RN - Sindicato Estadual dos Trabalhadores em Educação do Ensino Superior do Rio Grande do Norte<br />
            Sindicato Estadual dos Profissionais de Educação do Rio de Janeiro - Petrópolis<br />
            Sindicato Nacional dos Servidores Federais da Educação Básica, Profissional e Tecnológica - Seção São Paulo<br />
            SINDISAÚDE-RS<br />
            DCE Celso Lisboa (RJ)<br />
            DCE IFRS POA (RS)<br />
            DCE Raul Amaro - PUC-Rio (RJ)<br />
            DCE UEVA (CE)<br />
            DCE UFLA (MG)<br />
            DCE UFOPA (PA)<br />
            DCE UFPA (PA)<br />
            DCE UFRGS (RS)<br />
            DCE UFTM (MG)<br />
            DCE UNIFAL (MG)<br />
            DCE Uninassau (PA)<br />
            DCE UFRN (RN)<br />
            DCE UERN (RN)<br />
            Brigada Popular contra o Covid 19 do Acre<br />
            Grupo de estudos e pesquisas Direito Penal e democracia (PA)<br />
            LACIGS - Liga Acadêmica de cuidados integrais a diversidade sexual e de gênero (PA)<br />
            LASIM - Liga Acadêmica de Saúde Integral da Mulher (PA)<br />
            Liga acadêmica de Psicologia Social Silvia Lane (PA)<br />
            LISMU - Liga Interdisciplinar em Saúde da Mulher da UFSCar (SP)<br />
            NUGS - Núcleo de Estudos sobre Gênero e Sexualidade do IFSP (SP)<br />
            Associação de Moradores do Crusp - USP (SP)<br />
            CAEnf - Centro Acadêmico da Enfermagem da UFSCar (SP)<br />
            CAPSI - UFMG (MG)<br />
            CAPSI Carolina Bori - FAMAZ (PA)<br />
            Capsiu Marielle Franco (PA)<br />
            CaQui - Centro Acadêmico da Química da UFSCar (SP)<br />
            Centro Acadêmico Arnaldo Vieira de Carvalho - FOFITO-USP (SP)<br />
            CA Luana Kumaruara - Centro Acadêmico de Antropologia UFOPA (PA)<br />
            Centro Acadêmico de Biofísica - UFRJ (RJ)<br />
            Centro Acadêmico de Biologia - UNESP Rio Preto (SP)<br />
            Centro Acadêmico de Biologia - PUC-Rio (RJ)<br />
            Centro Acadêmico de Ciências Sociais - UFAC (AC)<br />
            CAD Borari - Centro Acadêmico de Direito da UFOPA (PA)<br />
            Centro Acadêmico de Direito - UNB (DF)<br />
            CACLIO - Centro Acadêmico de História da UFOPA (PA)<br />
            Centro Acadêmico de Música - UFRJ (RJ)<br />
            Centro Acadêmico de Odontologia - UFRJ (RJ)<br />
            Centro Acadêmico de Pedagogia -UNESP Rio Preto (SP)<br />
            Centro Acadêmico de Pedagogia - PUC-Rio (RJ)<br />
            CAPSI - Centro Acadêmico de Psicologia da UFMA- Gestão Estamira (MA)<br />
            Centro Acadêmico de Psicologia - UNB (DF)<br />
            Centro Acadêmico de Relações Internacionais Maria José - Unama (PA)<br />
            Centro Acadêmico de Serviço Social - UNB (DF)<br />
            Centro Acadêmico de Serviço Social - PUC-Rio (RJ)<br />
            Centro Acadêmico Vladimir Herzog - Psicologia/FHO (SP)<br />
            Centro Acadêmicos de Comunicação - UFPA (PA)<br />
            CACS - Centro de Ciências Sociais Florestan Fernandes da UFMA (MA)<br />
            CeUPES - Centro Universitário de Pesquisa e Estudos Sociais da USP (SP)<br />
            DA FAFICH - UFMG (MG)<br />
            DA UEMG IBIRITÉ (MG)<br />
            Diretório Acadêmico de Licenciatura em Ciências da Natureza - USP (SP)<br />
            Diretório Acadêmico de Mídia - UFF (RJ)<br />
            DAIN - Diretório Acadêmico Indígena da UFOPA (PA)<br />
            Grêmio Dom Diogo (RS)<br />
            Grêmio Estudantil Honestino Guimarães do IFMA/CCH - Gestão Marielle Franco (MA)<br />
            Grêmio Provisório Edson Presente - Escola Dez de Setembro (RS)<br />
            CEDENPA - Centro de Estudos e Defesa do Negro do Pará<br />
            Coletivo ColoreAfro - IFSP/ Salto (SP)<br />
            Coletivo de Assistentes Sociais - IFSP (SP)<br />
            CEQ - Coletivo de Estudantes Quilombolas da UFOPA (PA)<br />
            Coletivo de Mulheres da Física - UFRJ (RJ)<br />
            Coletivo Feminista Helen Keller<br />
            Coletivo Mãe Terra - Guarulhos (SP)<br />
            Coletivo Maria Plural (RS)<br />
            Coletivo Mulheres que Lutam (AC)<br />
            Coletivo Noéli - Coletivo de Mulheres da Filosofia UERJ (RJ)<br />
            Coletivo Nós por Nós - Salto (SP)<br />
            Coletivo Salto Por elas - Salto (SP)<br />
            Coletivo SOMAS - Jaú (SP)<br />
            Filósofas na Rede<br />
            Fórum Estadual de Juventude de Terreiro do Pará<br />
            Literocupa - Jaú (SP)<br />
            Movimento Cidade Para Elas - Itu (SP)<br />
            Movimento de Mulheres de Marituba Todas por Todas (PA)<br />
            Organização Filantrópica Maré (PA)<br />
            Página “Política para mulheres”<br />
            Periferia em Foco (PA)<br />
            Pretas Paridas de Amazônia (PA)<br />
            Unei- União negra ituana (SP)<br />
            Universitários na Luta (PA)
          </p>
        </div>
      </main>

      <div id="sign" className="signatures">
        <div className="wrap">
          <h2 className="signatures-title">Assine o manifesto</h2>

          <Form
            forms={[
              CampaignForm,
              {
                ...CampaignFeedback,
                title: data.site.meta.title,
                url: data.site.meta.url,
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
