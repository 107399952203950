import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import ExtLink from './ExtLink';

import styles from './Button.module.scss';

const Button = ({to, children, bg, fg, fontSize, width, type, ...props}) => {
  const style = {background: bg, color: fg, fontSize: fontSize, width: width};
  if (type === 'submit') {
    return (
      <button type="submit" className={styles.button} style={style} {...props}>
        {children}
      </button>
    );
  }

  if (!to) {
    return (
      <span className={styles.button} style={style} {...props}>
        {children}
      </span>
    );
  }

  let Tag = null;
  if (to.startsWith('#')) {
    Tag = 'a';
  } else if (to.startsWith('http:') || to.startsWith('https:')) {
    Tag = ExtLink;
  } else {
    Tag = Link;
  }

  return (
    <Tag className={styles.button} href={to} style={style} to={to} {...props}>
      {children}
    </Tag>
  );
};

Button.defaultProps = {
  to: '',
  children: null,
  bg: false,
  fg: false,
  fontSize: false,
  type: '',
  width: 'auto',
};

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  bg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  width: PropTypes.string,
};

export default Button;
