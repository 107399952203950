import React from 'react';
import PropTypes from 'prop-types';

import ShareButtons from './ShareButtons';

const CampaignFeedback = ({title, url}) => (
  <>
    <p><strong>Obrigada por assinar!</strong> Agora ajude a espalhar compartilhando nas suas redes sociais.</p>

    <ShareButtons title={title} url={url} />
  </>
);

CampaignFeedback.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

CampaignFeedback.element = CampaignFeedback;

export default CampaignFeedback;
